<template>
  <div class="article-page">
    <div class="detail-bg">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="item in breadConfig" :key="item.title" :to="{ name: item.name, params: { type: item.type } }">{{ item.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="article-title">{{ article.title }}</div>
      <div class="article-time">{{ article.publish_time }}</div>
      <div class="article-content" v-html="article.content"></div>
      <div class="article-attachment">
        <a :href="article.sub_file_link">{{ article.sub_file_name }}</a>
      </div>
    </div>
    <div class="article-footer"></div>
  </div>
</template>

<script>

import NewsAPI from '@/api/v1/news'
import store from '@/store'

export default {
  name: "",
  data() {
    return {
      article: {},
    };
  },
  computed: {
    breadConfig() {
      let from = parseInt(this.$route.params.from)
      if (from === 0) {
        return null//[{title: '首页', name: 'home'}]
      }
      else if (from === 1) {
        return [{title: '通知公告', name: 'newsList', type: 1}]
      }
      else if (from === 6) {
        return [{title: '相关业务', name: 'newsList', type: 6}]
      }
      else if (from === 2) {
        return [{title: '政策法规', name: 'newsList', type: 2}]
      }
      else if (from === 3) {
        return [
          {title: '新闻中心' , name: 'newsList', type: 3},
          {title: '重点新闻' , name: 'newsList', type: 3}
        ]
      }
      else if (from === 5) {
        return [
          {title: '新闻中心' , name: 'newsList', type: 3},
          {title: '地方动态' , name: 'newsList', type: 5}
        ]
      }
      return null
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let articleId = this.$route.params.id;
      articleId = BigInt(articleId);
      NewsAPI.getNewsDetail(articleId).then((response) => {
        this.article = response
      }).finally(() => {

      })
    },
    downloadFile() {
      let fileURL = this.article.sub_file_link.replace("http", "https");
      window.open(fileURL);
    },
  },
  beforeRouteEnter(to, from, next) {
    let fromIndex = parseInt(to.params.from)
    let index = '1'
    if (fromIndex === 0) {
        index = '1'
      }
      else if (fromIndex === 1) {
        index = '3'
      }
      else if (fromIndex === 2) {
        index = '5'
      }
      else if (fromIndex === 3) {
        index = '4'
      }
      else if (fromIndex === 5) {
        index = '4'
      }
      else if (fromIndex === 6) {
        index = '4'
      }
    store.commit('navbar/SET_POSITION_BOTTOM')
    let pageIndex = { activeIndex: index}
    store.commit('navbar/SET_ACTIVEINDEX', pageIndex)
    next()
  },
};
</script>

<style lang="stylus" scoped>

@media only screen and (min-width: 600px) {
.article-page {
  background #f6f6f6
  display flex
  flex-direction column
  flex-grow 1
}

.navbar {
  flex-grow 0
  flex-shrink 0
  height 365px
}

.detail-bg {
  margin 0 auto
  width 800px
  background white
  padding 33px 55px
  flex-grow 1
  flex-shrink 0
}

/deep/.el-breadcrumb__inner a,
/deep/.el-breadcrumb__inner.is-link,
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size 16px
  color #818181
  cursor pointer
}

.article-title {
  margin-top 70px
  font-size 20px
  
  color #818181
  text-align center
  width 100%
}

.article-time,
.article-content {
  font-size 14px
  
  color #818181
  line-height 30px
}

.article-time {
  margin-top 10px
  text-align center
  width 100%
}

.article-content {
  margin-top 43px
}

.download-button {
  border none
  color #409EFF
  text-decoration underline

  &:hover {
    text-decoration underline
    background white
  }
}
.article-footer {
  height 80px
}

}


// mobile


@media only screen and (max-width: 600px) {
.article-page {
  background #f6f6f6
  min-height 100%
  display flex
  flex-direction column
}

navbar {
  flex-grow 0
  flex-shrink 0
}

.detail-bg {
  margin 0 auto
  background white
  padding 33px 25px
  max-width 100%
  flex-grow 0
  flex-shrink 0
}

/deep/.el-breadcrumb__inner a,
/deep/.el-breadcrumb__inner.is-link,
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size 16px
  
  color #818181
}

.article-title {
  margin-top 70px
  font-size 20px
  
  color #818181
  text-align center
  width 100%
}

.article-time,
.article-content {
  font-size 14px
  
  color #818181
  line-height 30px
}

.article-time {
  margin-top 10px
  text-align center
  width 100%
}

.article-content {
  margin-top 43px
  max-width 100%

  /deep/img {
    max-width 100%
    object-fit cover
  }
}

.download-button {
  border none
  color #409EFF
  text-decoration underline
  
  &:hover {
    text-decoration underline
    background white
  }
}

}
</style>