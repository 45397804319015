var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article-page" }, [
    _c(
      "div",
      { staticClass: "detail-bg" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { "separator-class": "el-icon-arrow-right" } },
          _vm._l(_vm.breadConfig, function(item) {
            return _c(
              "el-breadcrumb-item",
              {
                key: item.title,
                attrs: { to: { name: item.name, params: { type: item.type } } }
              },
              [_vm._v(_vm._s(item.title))]
            )
          }),
          1
        ),
        _c("div", { staticClass: "article-title" }, [
          _vm._v(_vm._s(_vm.article.title))
        ]),
        _c("div", { staticClass: "article-time" }, [
          _vm._v(_vm._s(_vm.article.publish_time))
        ]),
        _c("div", {
          staticClass: "article-content",
          domProps: { innerHTML: _vm._s(_vm.article.content) }
        }),
        _c("div", { staticClass: "article-attachment" }, [
          _c("a", { attrs: { href: _vm.article.sub_file_link } }, [
            _vm._v(_vm._s(_vm.article.sub_file_name))
          ])
        ])
      ],
      1
    ),
    _c("div", { staticClass: "article-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }